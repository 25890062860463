import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Alert from "../components/alert"
import HeaderImg from "../images/header_bg_3.png"
import IconWarn from "../images/icon_alert.svg"
import { above, TPSmall, TPReg, Wrapper } from "../components/global"
import Quote from "../components/quote"

const Clients = styled.section`
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    padding: 40px 0;
    ${above.small`
        grid-template-columns: 1fr 1fr 1fr;
        gap: 100px;
        padding: 120px 0;
    `}
`

const Client = styled.div`

`

const TestimonialPage = () => (
  <Layout pageTitle="Testimonials" pageBG={HeaderImg}>
    <SEO title="Customer Testimonials" />
    <Alert alertIcon={IconWarn} alertTitle="Covid-19" alertText="We can do contactless payments &amp; email invoices. We disinfect all keys and steering wheels etc before &amp; after we get in your vehicle!" />
    
    <Wrapper>
        <Clients>
            <Client>
                <TPReg>Always high quality excellent service and willing to go out of his way to get a job done. Would highly recommend.</TPReg>
                <TPSmall>Laura Jones</TPSmall>
            </Client>
            <Client>
                <TPReg>Superb service and always does a fantastic job with great attention to detail always goes above and beyond, pure quality service would definitely recommend.</TPReg>
                <TPSmall>Sam Morrison</TPSmall>
            </Client>
            <Client>
                <TPReg>Have always received a professional and efficient service with Hoylake Autos ltd. There is no one else I would have do my cars as they work so hard and are dedicated to what they do.</TPReg>
                <TPSmall>Sophie Lewis</TPSmall>
            </Client>
        </Clients>
    </Wrapper>

    <Quote />
  </Layout>
)

export default TestimonialPage
